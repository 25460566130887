<template>
    <td class="py-2 text-center" :style="actionTdStyles">
        <CButton v-if="!disableEdit" color="warning" class="mr-2" size="sm" @click="emitEdit" title="Edit"><CIcon name="cil-pencil"/></CButton>
        <CButton v-if="!disableEdit" color="danger" size="sm" @click="deleteModal = true"  title="Delete"><CIcon name="cil-trash"/></CButton>
        <CButton v-if="isKpisForm" color="warning" class="ml-2" size="sm" @click="changePosition = true" title="Move"><CIcon name="cil-swap-vertical"/></CButton>
        <CButton v-if="isKpisForm" color="info" class="ml-2" size="sm" @click="emitToggleDetails" title="Details"><CIcon name="cil-plus"/></CButton>
        <CModal title="Delete item" color="danger" :show.sync="deleteModal" @update:show="removeItem">
          Are you sure you want to delete '{{identifier}}' from '{{type}}'
        </CModal>
        <CModal :title="'Move item - ' + identifier" color="warning" :show.sync="changePosition" @update:show="changeItemsPosition">
          Target position ID (pass the ID of the KPI that is now on position you want this KPI to be placed in)
          <CInput v-model="moveTo" class="mt-3 w-50 mx-auto" />
        </CModal>
    </td>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ActionsTD',
    props: ['type', 'item', 'itemIndex'],
    data() {
      return {
        deleteModal: false,
        changePosition: false,
        moveTo: null,
        namedTypes: ['kpis', 'teams', 'owners'],
      }
    },
    computed: {
      ...mapState({
        userEmail: state => state.auth.user.email,
        adminRole: state => state.auth.user.adminRole,
      }),
      identifier() {
        if (this.namedTypes.includes(this.type)) {
          return this.item.name
        } else if (this.type == 'values') {
          return this.item.date + ' - ' + this.item.value
        } else {
          return null
        }
      },
      isKpisForm() {
        return this.type == 'kpis' ? true : false
      },
      disableEdit() {
        let disableEdit = false
        if (this.type == 'kpis' && this.adminRole != 'global' && (!this.item.editors_emails || !this.item.editors_emails.some(item => item.editors_email.toLowerCase() == this.userEmail.toLowerCase() ))) {
          disableEdit = true
        }
        return disableEdit
      },
      actionTdStyles() {
        return {
          'min-width': this.isKpisForm ? '185px' : '100px'
        }
      }
    },
    methods: {
      removeItem(_, _2, accept) {
        if (accept) {
          this.$store.dispatch('kpiData/removeApiState', {name: this.type, id: this.item.id})
        }
      },
      changeItemsPosition(_, _2, accept) {
        if (accept && this.moveTo) {
          this.$store.dispatch('kpiData/changeItemPosition', {name: this.type, item: this.item.id, to: this.moveTo})
        }
      },
      emitEdit() {
        this.$emit('editItem', this.item.id)
      },
      emitToggleDetails() {
        this.$emit('toggleDetails', this.itemIndex)
      }
    }
}
</script>